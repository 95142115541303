var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.category
        ? _c("a-tag", { attrs: { color: "blue" } }, [
            _vm._v(" " + _vm._s(_vm.category) + " ")
          ])
        : _c(
            "div",
            {
              staticStyle: {
                width: "50px",
                display: "flex",
                "justify-content": "center"
              }
            },
            [_vm._v(" - ")]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }