//
//
//
//
//
//

/* eslint-disable no-undef */
import { getCodeStatusColorCSS, getCodeStatusName } from '@/components/_util/util';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    colorStatus() {
      return getCodeStatusColorCSS(this.status);
    },
    status() {
      const status = getCodeStatusName(this.params.data).toLowerCase();
      return status.charAt(0).toUpperCase() + status.slice(1);
    }
  }
});
