//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';
import FileSaver from 'file-saver';
import ImageReview from '@/components/tools/ImageReview';
import { EC_ERROR_LEVEL, CODE_SIZE } from '@/enum';
export default Vue.extend({
  components: {
    ImageReview
  },
  computed: {
    url() {
      return this.params.data.preview_qrcode;
    },
    title() {
      return this.params.data.title;
    },
    statusCode() {
      return this.params.data.status;
    },
    errorLevel() {
      return EC_ERROR_LEVEL[this.params.data.sym_ec_level];
    },
    codeSize() {
      return CODE_SIZE[this.params.data.sym_size];
    }
  },
  methods: {
    downloadLink() {
      const timestamp = new Date().getTime();
      FileSaver.saveAs(
        `${this.params.data.preview_qrcode}?time=${timestamp}`,
        `univoice_code${this.params.data.id}.bmp`
      );
    }
  }
});
