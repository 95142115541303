//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';

export default Vue.extend({
  computed: {
    codeId() {
      return this.params.data?.id;
    },
    pageId() {
      return this.$route.params.pageId;
    },
    category() {
      return this.params.data.category?.name || null;
    }
  },

  data() {
    return {};
  },

  methods: {}
});
