//
//
//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';

export default Vue.extend({
  computed: {
    codeType() {
      return this.params.data.code_type;
    },
    colorType() {
      const mapping = {
        voice_code: 'pink',
        spot_code: 'cyan',
        navi_code: 'blue',
        mix_code: 'purple'
      };
      return mapping[this.codeType];
    }
  }
});
