var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.loading } },
    [
      _c(
        "a-row",
        { staticClass: "codes-wrapper", attrs: { gutter: [16, 16] } },
        [
          _vm._l(_vm.rowData, function(code, index) {
            return _c(
              "a-col",
              { key: index, attrs: { xs: 24, xl: 12, xxl: 8 } },
              [
                _c(
                  "a-card",
                  { attrs: { bodyStyle: { padding: "16px 16px 24px" } } },
                  [
                    code.preview_qrcode && code.code_type !== "voice_code"
                      ? _c(
                          "div",
                          { staticStyle: { position: "relative" } },
                          [
                            _c(
                              "a-carousel",
                              { attrs: { arrows: "", dots: false } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-slick-arrow left-arrow",
                                    attrs: { slot: "prevArrow" },
                                    slot: "prevArrow"
                                  },
                                  [
                                    _c("a-icon", {
                                      attrs: { type: "left-circle" }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-slick-arrow right-arrow",
                                    attrs: { slot: "nextArrow" },
                                    slot: "nextArrow"
                                  },
                                  [
                                    _c("a-icon", {
                                      attrs: { type: "right-circle" }
                                    })
                                  ],
                                  1
                                ),
                                code.preview_points === null
                                  ? _c(
                                      "div",
                                      { staticClass: "thumbnail-img" },
                                      [
                                        _c("img", {
                                          directives: [
                                            { name: "lazy", rawName: "v-lazy" }
                                          ],
                                          attrs: { alt: "" }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._l(code.preview_points, function(point, i) {
                                  return _c(
                                    "div",
                                    { key: i, staticClass: "thumbnail-img" },
                                    [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "lazy",
                                            rawName: "v-lazy",
                                            value: point.url,
                                            expression: "point.url"
                                          }
                                        ],
                                        attrs: { alt: "" }
                                      })
                                    ]
                                  )
                                })
                              ],
                              2
                            ),
                            _c(
                              "div",
                              { staticClass: "qrcode" },
                              [
                                _c("image-review", {
                                  attrs: { data: code.preview_qrcode, size: 68 }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : code.preview_qrcode
                      ? _c(
                          "div",
                          { staticStyle: { position: "relative" } },
                          [
                            _c(
                              "a-carousel",
                              { attrs: { arrows: "", dots: false } },
                              [
                                _c(
                                  "div",
                                  {
                                    ref: "boxContent",
                                    refInFor: true,
                                    staticClass: "thumbnail-img",
                                    staticStyle: { postition: "relative" }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/gray-background.png")
                                      }
                                    }),
                                    _c("div", {
                                      staticClass: "thumbnail-img--content1",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$options.filters.nl2br(
                                            code.codes[0].text
                                          )
                                        )
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "qrcode" },
                              [
                                _c("image-review", {
                                  attrs: { data: code.preview_qrcode, size: 68 }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticStyle: { position: "relative" } },
                          [
                            _c(
                              "a-carousel",
                              { attrs: { arrows: "", dots: false } },
                              [
                                _c("div", { staticClass: "thumbnail-img" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/gray-background.png")
                                    }
                                  }),
                                  code.code_type === "voice_code"
                                    ? _c("div", {
                                        staticClass: "thumbnail-img--content2",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$options.filters.nl2br(
                                              code.codes[0].text
                                            )
                                          )
                                        }
                                      })
                                    : _c("img", {
                                        staticClass: "qrcode",
                                        staticStyle: {
                                          width: "50%",
                                          height: "50%"
                                        },
                                        attrs: {
                                          src: require("@/assets/draft-icon.svg"),
                                          alt: ""
                                        }
                                      })
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                    _c(
                      "div",
                      { staticClass: "thumbnail--code-content" },
                      [
                        _c(
                          "a-row",
                          {
                            staticClass: "my-xs",
                            attrs: {
                              gutter: 16,
                              type: "flex",
                              justify: "space-between"
                            }
                          },
                          [
                            _c(
                              "a-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "a-tag",
                                  { staticStyle: { "font-size": "15px" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm._f("formatId")(code.id, "CO"))
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("a-col", { attrs: { span: 18 } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "pl-sm font-bold",
                                  staticStyle: {
                                    "font-size": "16px",
                                    "text-align": "right",
                                    width: "100%",
                                    "white-space": "nowrap",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis"
                                  }
                                },
                                [_vm._v(" " + _vm._s(code.title) + " ")]
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "a-row",
                          {
                            staticClass: "my-xs",
                            attrs: {
                              gutter: 16,
                              type: "flex",
                              justify: "space-between"
                            }
                          },
                          [
                            _c(
                              "a-col",
                              { attrs: { xs: 8 } },
                              [
                                _c(
                                  "a-tag",
                                  {
                                    attrs: {
                                      color: _vm.colorCodeType[code.code_type]
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t(code.code_type)) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { staticClass: "text-right", attrs: { xs: 16 } },
                              [
                                _c("span", { staticClass: "pr-sm" }, [
                                  _vm._v(_vm._s(_vm.$t("Status")) + ":")
                                ]),
                                _c(
                                  "span",
                                  { style: { color: _vm.colorStatus(code) } },
                                  [_vm._v(_vm._s(_vm.$t(_vm.status(code))))]
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-row",
                          {
                            staticClass: "my-xs",
                            attrs: {
                              gutter: 16,
                              type: "flex",
                              justify: "space-between"
                            }
                          },
                          [
                            _c("a-col", { attrs: { xs: 24 } }, [
                              _c("span", { staticClass: "pr-sm" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("code.grid.Category")) + ":"
                                )
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.categoryName(code)))
                              ])
                            ])
                          ],
                          1
                        ),
                        _c(
                          "a-row",
                          {
                            staticClass: "mt-xs",
                            attrs: {
                              gutter: 16,
                              type: "flex",
                              justify: "space-between"
                            }
                          },
                          [
                            _c("a-col", [
                              _c("span", { staticClass: "pr-sm" }, [
                                _vm._v(_vm._s(_vm.$t("Created")) + ":")
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm._f("dayjs")(code.created)))
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          }),
          _vm.rowData.length === 0
            ? _c("p", { staticClass: "mt-md text-center" }, [
                _vm._v(_vm._s(_vm.$t("No data")))
              ])
            : _vm._e()
        ],
        2
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "16px", "text-align": "right" } },
        [
          _c("a-pagination", {
            attrs: {
              "page-size-options": ["10", "20", "24", "30", "40", "50"],
              "show-size-changer": "",
              "page-size": _vm.pagination.pageSize,
              total: _vm.pagination.total,
              "hide-on-single-page": "",
              "show-total": function(total) {
                return _vm.$t("Total") + " " + total
              }
            },
            on: {
              "update:pageSize": function($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
              change: _vm.onPageChange,
              showSizeChange: _vm.onShowSizeChange
            },
            model: {
              value: _vm.current,
              callback: function($$v) {
                _vm.current = $$v
              },
              expression: "current"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }