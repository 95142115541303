//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileSaver from 'file-saver';
import ImageReview from '@/components/tools/ImageReview';
import { mapGetters, mapActions } from 'vuex';
import { getCodeStatusColorCSS, getCodeStatusName } from '@/components/_util/util';
export default {
  components: {
    ImageReview
  },
  props: {
    rowData: { type: Array, require: true, default: () => [] },
    pagination: { type: Object, require: true, default: () => ({}) },
    context: { type: Object, require: true, default: () => ({}) }
  },
  data() {
    return {
      loading: false,
      current: 1,
      visible: false,
      codeSelected: null
    };
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('code', ['codeById']),
    colorCodeType() {
      return {
        voice_code: 'pink',
        spot_code: 'cyan',
        navi_code: 'blue',
        mix_code: 'purple'
      };
    },
    pageId() {
      return this.$route.params.pageId;
    },
    projectId() {
      return this.$route.params.projectId;
    }
  },

  created() {
    this.current = this.pagination.current;
  },

  methods: {
    ...mapActions('code', ['addCode', 'removeCode', 'updateCode']),
    colorStatus(code) {
      return getCodeStatusColorCSS(code.status);
    },
    status(code) {
      return getCodeStatusName(code);
    },
    categoryName(code) {
      if (code.category) {
        let name = code.category.name;
        let maxlength = 30;
        return name.length > maxlength ? name.substr(0, maxlength) + '...' : name;
      }
      return '-';
    },
    onPageChange(page, pageSize) {
      this.$emit('onPageChange', { page, pageSize });
    },
    onShowSizeChange(current, pageSize) {
      this.$emit('onPageChange', { page: current, pageSize });
    },

    downloadLink(codeId) {
      const code = this.codeById(codeId);
      const timestamp = new Date().getTime();
      code &&
        code.preview_qrcode &&
        FileSaver.saveAs(`${code.preview_qrcode}?time=${timestamp}`, `univoice_code${code.id}.bmp`);
    }
  }
};
