//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';

export default Vue.extend({
  computed: {
    codeId() {
      return this.params.data.id;
    },
  }
});
