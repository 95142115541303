//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {};
  },

  computed: {
    title() {
      return this.params.data?.title;
    }
  },

  methods: {}
});
