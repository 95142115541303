//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import SearchBox from '@/views/project/page/codes/search-box/SearchBox.vue';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';
import {
  IdCellRenderer,
  TitleCellRenderer,
  TypeCellRenderer,
  QrCodeCellRenderer,
  StatusCellRenderer,
  CreatedCellRenderer,
  GridList,
  CategoryRenderer,
  PhotoCellRenderer
} from './grid';
const PAGE_SIZE = 24;
export default {
  components: {
    SearchBox,
    GridList,
    PageHeader,
    IdCellRenderer,
    TitleCellRenderer,
    TypeCellRenderer,
    QrCodeCellRenderer,
    StatusCellRenderer,
    CreatedCellRenderer,
    CategoryRenderer,
    PhotoCellRenderer
  },
  data() {
    return {
      context: { componentParent: this },
      visibleModal: false,
      rowData: [],
      loading: false,
      fetching: false,
      queryParam: {
        status: 'all',
        title: '',
        mode: this.$ls.get('mode', 'grid'),
        category: ''
      },
      pagination: { current: 1, pageSize: PAGE_SIZE, total: 0 },

      //category
      category: {
        options: [],
        normalizer(node) {
          return {
            label: node.name
          };
        }
      }
    };
  },
  created() {
    const { title, status, mode, category } = this.$route.query;
    this.queryParam = {
      title: title || '',
      status: status || 'all',
      mode: mode || this.$ls.get('mode', 'grid'),
      category: category || ''
    };
    this.pagination.pageSize = +this.$route.query.size || PAGE_SIZE;
    this.pagination.current = +this.$route.query.page || 1;
    this.getData();
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('page', ['pageById']),
    ...mapGetters('code', ['codes', 'codeById']),
    currentPage() {
      return this.pageById(this.$route.params.pageId) || {};
    },
    columnDefs() {
      return [
        {
          headerName: 'code.grid.ID',
          field: 'id',
          width: 120,
          sortable: true,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'IdCellRenderer'
        },
        {
          headerName: 'code.grid.Photo',
          field: 'photo',
          width: 180,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'PhotoCellRenderer'
        },
        {
          headerName: 'code.grid.Title',
          field: 'title',
          // width: 200,
          suppressSizeToFit: true,
          resizable: true,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'TitleCellRenderer'
        },
        {
          headerName: 'code.grid.Category',
          field: 'category',
          lockPosition: true,
          cellClass: 'locked-col',
          width: 150,
          cellRenderer: 'CategoryRenderer'
        },
        {
          headerName: 'code.grid.Type',
          field: 'type',
          width: 150,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'TypeCellRenderer'
        },
        {
          headerName: 'Voice code',
          field: 'preview_qrcode',
          width: 150,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'QrCodeCellRenderer'
        },
        {
          headerName: 'code.grid.Status',
          field: 'status',
          width: 150,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'StatusCellRenderer'
        },
        {
          headerName: 'code.grid.Created',
          field: 'created',
          width: 120,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'CreatedCellRenderer'
        }
      ];
    },
    pageId() {
      return this.$route.params.pageId;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    companyId() {
      return this.userInfo.company_id;
    },
    filter() {
      const { title, status, category } = this.queryParam;
      let where = {
        ...(title && { title: { ilike: `%${title}%` } }),
        ...(status && status !== 'all' && { status: { eq: status } }),
        ...(category && { category_id: { inq: JSON.parse(category) } })
      };
      const skip = (this.pagination.current - 1) * this.pagination.pageSize;
      const filter = {
        limit: this.pagination.pageSize,
        skip,
        where,
        order: 'created DESC'
      };
      return filter;
    }
  },
  methods: {
    ...mapActions('code', ['loadCodes', 'reset']),
    ...mapActions('page', ['loadPages', 'getPageById']),
    onChangeView(mode) {
      const { query, path } = this.$route;
      this.$ls.set('mode', mode);
      this.$router.replace({ path, query: { ...query, mode: mode } });
    },
    async getData() {
      try {
        this.loading = true;
        this.reset();
        // const [codes, count] = await Promise.all([
        const codes = await this.$s.api.page.getCodes(this.pageId, { filter: this.filter });
        const count = await this.$s.api.page.countCodes(this.pageId, this.filter.where);
        let result = await this.$s.api.page.getCategories(this.pageId);
        this.getPageById({ projectId: this.projectId, pageId: this.pageId });
        this.category.options = result;
        // ]);
        this.pagination = { ...this.pagination, total: count?.count };
        this.loadCodes({ payload: codes });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    changeTitle(value) {
      this.queryParam.title = value;
    },
    changeStatus(value) {
      this.queryParam.status = value;
      this.$router.replace({ query: this.queryParam });
    },
    handleSearch() {
      this.$router.replace({ query: this.queryParam });
    },
    handleReset() {
      Object.assign(this.queryParam, {
        title: '',
        status: 'all',
        mode: this.$route.query.mode || '',
        category: ''
      });
      this.handleSearch();
    },

    handleChangePage({ page, pageSize }) {
      this.pagination = { ...this.pagination, current: page, pageSize };
      this.getData();
    },
    changeCategory(ids) {
      this.queryParam.category = ids.length ? JSON.stringify(ids) : '';
      this.$router.replace({ query: this.queryParam });
    }
  }
};
