var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.url && _vm.statusCode !== "DRAFT"
    ? _c(
        "div",
        { staticClass: "qr-wrap" },
        [
          _c(
            "a-tooltip",
            { attrs: { placement: "top" } },
            [
              _c("image-review", { attrs: { data: _vm.url, size: 95 } }),
              _c("template", { slot: "title" }, [
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("Code size")) + ": " + _vm._s(_vm.codeSize)
                  )
                ]),
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("EC error level")) +
                      ": " +
                      _vm._s(_vm.errorLevel)
                  )
                ])
              ])
            ],
            2
          ),
          _c(
            "a-button",
            { attrs: { type: "link" }, on: { click: _vm.downloadLink } },
            [_vm._v(_vm._s(_vm.$t("Download")))]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }