//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';

export default Vue.extend({
  computed: {
    thumbnailURL() {
      return this.params.data?.thumbnail?.url || '/img/noimage.svg';
    },
    thumbnailALT() {
      return this.params.data?.thumbnail?.alt;
    }
  }
});
